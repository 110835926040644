export default {
  name: "neo-checkbox",
  components: {},
  props: ["value", "label", "bg", "borderColor","disabled", 'labelContainerClassList'],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
