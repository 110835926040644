<template>
  <div class="app-settings flex items-start gap-5 h-full">
    <Block
      title="Theme Admin"
      class="w-80 max-h-full"
      contentClass="flex flex-col gap-3 overflow-auto"
    >
      <div class="settings-block p-4 rounded-lg bg-gray-100">
        <LogoInput
          :tenant_id="getTenantId"
          :value="getThemeLogo"
          @input="saveLogo"
          @removeLogo="removeLogo"
        />
      </div>
      <label class="useDefault flex items-center gap-2">
        <Checkbox
          class="useDefault__checkbox"
          v-model="useDefault"
          @input="setUseDefault"
        />
        <span class="text-base-content text-xs font-semibold cursor-pointer">
          Use default system settings
        </span>
      </label>
      <div
        class="settings-block p-4 rounded-lg bg-gray-100 flex flex-col gap-3 overflow-auto scroll-bar"
        :class="{'cursor-not-allowed': useDefault}"
      >
        <ColorPicker
          v-for="(color, key) in colors"
          :key="key"
          :class="{'opacity-70': useDefault}"
          :name="color.name"
          :value="color.value"
          :disabled="useDefault"
          @change="applyColor(key, $event)"
        />
      </div>
      <div class="flex justify-between gap-1">
        <Button
          class="w-24"
          text="Cancel"
          @click="cancelColors"
        />
        <Button
          class="w-24"
          text="Save"
          type="primary"
          @click="saveColors"
        />
      </div>
    </Block>
    <Block
      class="flex-1 self-stretch"
      contentClass="flex items-center justify-center text-gray-500"
    >
      <!-- Preview functionality will be available soon -->

      <Preview /> 
    </Block>
  </div>
</template>

<script>
import Block from '@shared/components/block-container';
import ColorPicker from '@/components/color-picker';
import Checkbox from "@/components/checkbox";
import Button from "@/components/button";
import LogoInput from './components/ImageUploader';
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "@/axios"
import Preview from "./components/preview"


/* 
  Color sets:
    - getDefaultColors - hardcoded default colors.
    - getTenantConfig.uiconfig.color_config - colors from server.
    - getThemeColors - Colors applied on the frontend.
  Store: tenant-app/src/modules/theme/store.js

  On init:
    - reset_flag:
      - true:
        - 'Use default system settings' = true.
        - Parse colors from server.
        - Use these colors as default.
      - false:
        - 'Use default system settings' = false.
        - Parse hardcoded colors.
        - These colors are default.

  Actions:
    - 'Apply' color - set new color value in FRONTEND config (not default).
    - 'Cancel' color - reset color to its applied on the frontend value.
    - 'Save' - send all applied colors & logo to the API; reset colors to default values if useDefault.
    - 'Cancel' - reset all colors & logo to saved (server) values.
*/

// TODO Don't use default colors from store - just skip colors which values hasn't been set
// TODO as their default values are already set at @shared/assets/styles/style.scss

export default {
  name: 'theme-admin',
  components: {
    Block,
    ColorPicker,
    Checkbox,
    Button,
    LogoInput,
    Preview,

  },
  data() {
    return {
      useDefault: false,
      colors: null,
      logo: '',
    }
  },
  created() {
    // this.logo = this.getThemeLogo;
    this.useDefault = this.getThemeUseDefault;
    this.setColorsFromFrontend();
  },
  computed: {
    ...mapGetters([
      'getTenantConfig',
      'getThemeColors',
      'getDefaultColors',
      'getThemeLogo',
      'getTenantId',
      'getThemeUseDefault',
      'getLoginTenantDetails'
    ]),
  },
  methods: {
    ...mapActions(['setTenant', 'setThemeLogo']),
    ...mapMutations([
      // 'SET_THEME_COLORS',
      'SET_THEME_COLOR',
      'SET_THEME_LOGO',
      'SET_TENANT_CONFIG',
      'SET_THEME_USE_DEFAULT',
    ]),
    setUseDefault(payload) {
      this.SET_THEME_USE_DEFAULT(payload);
      this.setColorsFromFrontend();
    },
    // use to populate component's local colors with values received from the server:
    setColorsFromServer() {
      const useDefault = this.useDefault;
      this.colors = Object.entries(this.getTenantConfig.uiconfig.color_config)
        .reduce((result, [key, value]) => {
            result[key] = {
              ...this.getThemeColors[key],
              value: useDefault ? this.getDefaultColors[key].value : value,
            }
            return result;
        }, {});
    },
    // use to populate component's local colors with temporary frontend colors' values:
    setColorsFromFrontend() {
      const useDefault = this.useDefault;
      this.colors = Object.entries(this.getThemeColors)
        .reduce((result, [key, value]) => {
          if (key in this.getTenantConfig.uiconfig.color_config) {
            result[key] = {
              ...this.getThemeColors[key],
              // if useDefault use default colors; else use color saved on the frontend if exist: 
              value: useDefault ? this.getDefaultColors[key].value : (value.value || this.getDefaultColors[key].value),
            }
          }
          return result;
        }, {});
    },
    applyColor(color, value) {
      this.colors[color].value = value;
      this.SET_THEME_COLOR({color, value});
    },
    // use to reset all applied on the frontend colors with local colors from this component:
    applyCurrentColors() {
      Object.entries(this.colors).forEach(([color, value]) => {
        this.applyColor(color, value.value);
      });
    },
    async saveLogo(logo) {
      try {
        await this.setTenant({
            ...this.getTenantConfig,
            logo_url: logo
        });
        this.setThemeLogo(logo);
        this.$toast.success("Saved Successfully!");
      } catch (error) {
        this.$toast.error("Failed to save");
      }
    },
    async removeLogo(file_logo) {
      try {
        await axios.delete(`/tenant/logo/${this.getTenantId}`,{data: {
          logo_url: file_logo
        }})

        let configs = this.getTenantConfig;
        configs.logo_url = "";
        this.setThemeLogo("");
        this.logo = "";
        this.SET_TENANT_CONFIG(configs);
        this.saveLogo("");
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    async saveColors() {
      try {
        const { uiconfig } = this.getTenantConfig;
        const { color_config } = uiconfig;
        // reset color if has been changed:
        const newColors = Object.entries(color_config).reduce((result, [key, value]) => {
          result[key] = this.getThemeColors[key].value || value;
          return result;
        }, {})
        const newConfig = {
          ...this.getTenantConfig,
          logo_url: this.getThemeLogo,
          reset_flag: this.useDefault,
          uiconfig: {
            ...uiconfig,
            color_config: newColors,
          }
        }
        this.SET_TENANT_CONFIG({...newConfig});
        await this.setTenant(newConfig);
        this.setColorsFromFrontend();
        // apply default colors' values on the frontend:
        if (this.useDefault) this.applyCurrentColors();
        this.$toast.success("Saved Successfully!");
      } catch (error) {
        this.$toast.error("Failed to save");
      }
    },
    cancelColors() {
      // reset all frontend changes in config and colors with values from the server:
      this.SET_TENANT_CONFIG(this.getTenantConfig);
      this.setColorsFromServer();
    },
  },
}
</script>

<style lang="scss" scoped>
.useDefault {
  &__checkbox {
    ::v-deep {
      .input-wrapper {
        display: flex;
      }
    }
  }
}
</style>